import React from "react"

import SEO from "components/seo"
import Layout from "components/layout"
import { TenantsList } from "components/pages/development/project/details/tenants/list"
import { GlobalMarketingGallery } from "components/global/marketing-gallery/marketing-gallery"
import { ProjectDetailsLeadForm } from "components/pages/development/project/details/lead-form"
import { Breadcrumb } from "components/anti/breadcrumb/breadcrumb"


export const Tenants = ({ pageContext, location }) => {
  const lang = pageContext.langKey
  const pageData = pageContext


  const { featuredImage } = pageContext
  const seo = lang === "en" ? pageContext?.data?.seo : pageContext?.data?.translation?.seo || pageContext?.data?.seo
  const marketingGallery =
    lang === "en" ?
      pageData?.data?.projectDetailsPage?.marketingGallery :
      pageData?.data?.translation?.projectDetailsPage?.marketingGallery ||
      pageData?.data?.projectDetailsPage?.marketingGallery

  return (
    <Layout path={location.pathname} lang={lang}>
      <SEO
        title={seo?.title}
        desc={seo?.metaDesc}
        fbImg={featuredImage?.node?.sourceUrl}
        fbTitle={seo?.opengraphTitle}
        fbDesc={seo?.opengraphDescription}
        url={location?.href}
        twitterTitle={seo?.twitterTitle}
        twitterDesc={seo?.twitterDescription}
        twitterImg={featuredImage?.node?.sourceUrl}
      />
      <section className="pt-4">
        <div className="container">
          <Breadcrumb path={location?.pathname} theme="light" />
        </div>
      </section>
      <TenantsList data={pageData?.tenants} />
      <GlobalMarketingGallery data={marketingGallery} />
      <ProjectDetailsLeadForm />
    </Layout>
  )
}

export default Tenants


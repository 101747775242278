import React from "react"

import { Button } from "components/anti/buttons/buttons"
import { useScrollAnim } from "src/components/hooks/hooks"

import BGSustain1 from "assets/img/bg_about_sustain_1.png"
import BGSustain2 from "assets/img/bg_about_sustain_2.png"
import BGSustain3 from "assets/img/bg_about_sustain_3.png"

export const GlobalMarketingGallery = ({ data }) => {
    const [trigger, anim] = useScrollAnim()

    return (
        <section className="pt-6 py-md-6 global-marketingGallery" ref={trigger}>
            <img className="bg-image curve-1" src={BGSustain1} />
            <img className="bg-image curve-2" src={BGSustain2} />
            <img className="bg-image curve-3" src={BGSustain3} />
            <div className="container" id="get-more-info">
                <div className="w-md-500px">
                    <h2 className={`${anim(1)}`}>{data?.title}</h2>
                    <p className={`text-white-700 ${anim(2)}`}>{data?.text}</p>
                    <Button variant="link" className={`text-white ${anim(3)}`} link={data?.button?.url}>
                        {data?.button?.text}
                    </Button>
                </div>
            </div>
        </section>
    )
}
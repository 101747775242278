import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

export const Breadcrumb = ({ path, theme, className }) => {
  const pathNames = typeof path === "string" && path.split("/")

  return (
    <ol className={`breadcrumb ${theme} ${className}`} data-testid="breadcrumb">
      {/* <li className="breadcrumb-item">
        <Link to="/">Home</Link>
      </li>
      <li className="breadcrumb-item">Cover</li> */}
      {typeof path === "string" &&
        pathNames.map((item, index) => {
          if (index === 0) {
            return (
              <li className="breadcrumb-item text-capitalize" key={`path-home`}>
                <Link to="/">Home</Link>
              </li>
            )
          } else if (index === pathNames.length - 1 && item !== "" && item !== "id") {
            return (
              <li
                className="breadcrumb-item text-capitalize"
                key={`path-${item.split("-").join(" ")}`}
              >
                {item.split("-").join(" ")}
              </li>
            )
          } else if (item === "" || item === "id") {
            return null
          } else {
            let url = pathNames.slice(0, index + 1).join("/")
            return (
              <li
                className="breadcrumb-item text-capitalize"
                key={`path-${item.split("-").join(" ")}`}
              >
                <Link to={url}>{item.split("-").join(" ")}</Link>
              </li>
            )
          }
        })}
    </ol>
  )
}

Breadcrumb.propTypes = {
  path: PropTypes.string,
  theme: PropTypes.oneOf(["", "light", "dark"]), // Set cover theme
  className: PropTypes.string,
}

Breadcrumb.defaultProps = {
  theme: "",
  className: "",
}

import React from "react"

import { Image } from "components/anti/image/image"
import { useScrollAnim } from "src/components/hooks/hooks"

export const TenantsList = ({ data }) => {
    const [trigger, anim] = useScrollAnim()

    return (
        <section className="pb-main" ref={trigger}>
            <div className="container">
                <h2 className={`mb-4 ${anim(1)}`}>Tenants</h2>
                <div className="row row-3">
                    {data?.map((tenant, i) => {
                        return (
                            <div className={`col-4 col-lg-2 mb-4 ${anim(2 + i)}`} key={i}>
                                <Image src={tenant?.tenantsImage?.image?.sourceUrl} ratio="r-4-3" className="bg-light img-fluid" alt="tenant" />
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}